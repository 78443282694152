import { inject }                         from 'aurelia-framework';
import { BaseFilterFormSchema }           from 'resources/classes/base-filter-form-schema';
import { SessionStorage }                 from 'resources/services/session-storage';
import { EventAggregator }                from 'aurelia-event-aggregator';
import { I18N }                           from 'aurelia-i18n';
import { UsersRepository }                from 'modules/administration/users/services/repository';
import { NotificationTypesRepository }    from 'modules/administration/notification-types/services/repository';
import { NotificationStatusesRepository } from 'modules/administration/notification-statuses/services/repository';

@inject(SessionStorage, EventAggregator, I18N, UsersRepository, NotificationTypesRepository, NotificationStatusesRepository)
export class FilterFormSchema extends BaseFilterFormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {
        task_type_id:    null,
        title:           null,
        created_at_from: null,
        created_at_to:   null,
        deadline_from:   null,
        deadline_to:     null,
        responsible_id:  null,
        status_id:       [1, 2], // unread & unhandled
    };

    /**
     * Constructor
     *
     * @param storage
     * @param eventAggregator
     * @param i18n
     * @param usersRepository
     * @param notificationStatusesRepository
     * @param notificationTypesRepository
     */
    constructor(storage, eventAggregator, i18n, usersRepository, notificationTypesRepository, notificationStatusesRepository) {
        super(storage, eventAggregator, i18n);

        this.usersRepository                = usersRepository;
        this.notificationStatusesRepository = notificationStatusesRepository;
        this.notificationTypesRepository    = notificationTypesRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns
     */
    model(viewModel) {
        return super.filterModel(viewModel, this.modelDefaults);
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        this.type_id = {
            type:         'select2',
            key:          'type_id',
            label:        'form.field.type',
            size:         3,
            options:      [],
            remoteSource: this.notificationTypesRepository.all.bind(this.notificationTypesRepository),
            required:     false,
        };

        this.title = {
            type:     'text',
            key:      'title',
            label:    'form.field.title',
            size:     3,
            required: false,

        };

        this.created_at_from = {
            type:     'material-ui-date-picker',
            key:      'created_at_from',
            label:    'form.field.created-at(from)',
            size:     3,
            required: false,
        };

        this.created_at_to = {
            type:     'material-ui-date-picker',
            key:      'created_at_to',
            label:    'form.field.created-at(to)',
            size:     3,
            required: false,
        };

        this.deadline_from = {
            type:     'material-ui-date-picker',
            key:      'deadline_from',
            label:    'form.field.deadline-from',
            size:     3,
            required: false,
        };

        this.deadline_to = {
            type:     'material-ui-date-picker',
            key:      'deadline_to',
            label:    'form.field.deadline-to',
            size:     3,
            required: false,
        };

        this.responsible_id = {
            type:         'select2',
            key:          'responsible_id',
            label:        'form.field.responsible',
            size:         3,
            options:      [],
            remoteSource: this.usersRepository.all.bind(this.usersRepository),
            required:     false,
        };

        this.status_id = {
            type:         'multiselect-native',
            key:          'status_id',
            label:        'form.field.status',
            size:         3,
            options:      [],
            remoteSource: this.notificationStatusesRepository.all.bind(this.notificationStatusesRepository),
            required:     false,
        };

        this.searchButton = {
            type:       'submit',
            label:      'form.button.search',
            action:     () => this.eventAggregator.publish('datatable-must-be-reloaded', {
                listingId: viewModel.listingId,
                criteria:  viewModel.filterModel,
            }),
            attributes: {
                class: 'btn btn-teal filter-submit',
            },
            icon:       {
                attributes: {
                    class: 'icon-search4',
                },
            },
        };

        this.clearButton = {
            type:       'button',
            label:      'form.button.clear',
            action:     () => this.eventAggregator.publish('datatable-filter-must-be-reseted', viewModel.listingId),
            attributes: {
                class: 'btn btn-light filter-reset',
            },
            icon:       {
                attributes: {
                    class: 'icon-close2',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.searchButton,
                this.clearButton,
            ],
        };

        return [
            [this.type_id, this.title, this.created_at_from, this.created_at_to],
            [this.deadline_from, this.deadline_to, this.responsible_id, this.status_id],
            [this.buttons],
        ];
    }
}
