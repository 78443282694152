import { inject }           from 'aurelia-framework';
import { CustomHttpClient } from 'resources/services/custom-http-client';
import { Downloader }       from 'resources/services/downloader';

@inject(CustomHttpClient, Downloader)
export class MessagesRepository {

    /**
     * Constructor
     *
     * @param httpClient
     * @param downloader
     */
    constructor(httpClient, downloader) {
        this.httpClient = httpClient;
        this.downloader = downloader;
    }

    /**
     * Performs a search given some criteria
     *
     * @param criteria
     *
     * @returns {*}
     */
    search(criteria) {
        return this.httpClient.post('personal-area/messages/search', criteria);
    }

    /**
     * Creates a record
     *
     * @param data
     *
     * @returns {*}
     */
    create(data) {
        return this.httpClient.post('personal-area/messages', data);
    }

    /**
     * Finds a record
     *
     * @param id
     *
     * @returns {*}
     */
    find(id) {
        return this.httpClient.get('personal-area/messages/' + id);
    }

    /**
     * Marks a message as read
     *
     * @param id
     *
     * @returns {*}
     */
    read(id) {
        return this.httpClient.get('personal-area/messages/' + id + '/read');
    }

    /**
     * Replies a message
     *
     * @param id
     * @param data
     *
     * @returns {*}
     */
    reply(id, data) {
        return this.httpClient.post('personal-area/messages/' + id + '/reply', data);
    }

    /**
     * Marks the given messages as read
     *
     * @param ids
     *
     * @returns {*}
     */
    readSelected(ids) {
        return this.httpClient.post('personal-area/messages/read-selected', { ids: ids });
    }

    /**
     * Destroys a record
     *
     * @param id
     *
     * @returns {*}
     */
    destroy(id) {
        return this.httpClient.delete('personal-area/messages/' + id + '/destroy');
    }

    /**
     * Destroys selected records
     *
     * @param ids
     *
     * @returns {*}
     */
    destroySelected(ids) {
        return this.httpClient.delete('personal-area/messages/destroy-selected', {ids: ids});
    }

    /**
     * Opens a file
     *
     * @param file
     */
    openFile(file) {
        this.getFile(file.id)
            .then(result => this.downloader.open(result, file.display_name, file.extension));
    }

    /**
     * Downloads a file
     *
     * @param file
     */
    downloadFile(file) {
        this.getFile(file.id)
            .then(result => this.downloader.download(result, file.display_name, file.extension));
    }

    /**
     * Gets a file
     *
     * @param id
     *
     * @returns {*}
     */
    getFile(id) {
        return this.httpClient.rawGet('personal-area/messages/files/' + id + '/preview')
            .then((response) => response.blob());
    }

}
